.screen-reader-text {
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border-width: 0;
	margin: -1px;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
}
