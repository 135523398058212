// base styles for html elements following our styling

th {
  text-align: left;
}

td img,
th img {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
img,
figure,
hr {
  margin-top: $margin-y-sm;
}

hr {
  margin-top: $margin-y-md;
}

figure img {
  margin-top: 0;
}

/**
* Default link styling
*/
a {
  color: var(--color-coral);
  transition: color 0.2s var(--ease-quad-out);
}

a:hover,
a:focus {
  text-decoration: none;
}

li::marker {
  font-weight: $weight-bold;
}

li {
  margin-top: rem(16px);
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

ol li,
ul li {
  padding-left: 0;
}

ul li {
  margin-left: 1.1em;
}

ol li {
  margin-left: 1em;
}

li:first-child {
  margin-top: 0;
}

figcaption,
.wp-block-image figcaption {
  margin-top: rem(15px);
  margin-bottom: 0;
  color: inherit;
  font-size: rem(14px);
  font-weight: $weight-medium;
  text-align: center;
}

figcaption img {
  display: inline-block;
  margin-right: rem(4px);
  margin-left: rem(4px);
  vertical-align: -0.5em;
}

hr {
  height: 2px;
  border: none;
  // gets around padding include border
  background-clip: content-box;
  background-color: #27175c;
}
