
$button-margin: rem(20px);

.wp-block-buttons {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	// flex-end as margin-top of arrow links is shorter
	align-items: flex-end;

	// negate extra margin of buttons between each other
	margin-top: $margin-y-md - $margin-button;
}

.wp-block-buttons.is-vertical {
	flex-direction: column;
}

.wp-block-buttons > .wp-block-button {
	margin-top: $margin-button;
	margin-right: $margin-button;
	margin-bottom: 0;
	margin-left: 0;
}

.wp-block-buttons.is-vertical > .wp-block-button {

	/*rtl:ignore*/
	margin-right: 0;

	&:last-child {
		margin-bottom: 0;
	}
}

.wp-block-buttons.is-content-justification-right > .wp-block-button {

	/*rtl:ignore*/
	margin-right: 0;

	/*rtl:ignore*/
	margin-left: $margin-button;

	&:first-child {

		/*rtl:ignore*/
		margin-left: 0;
	}
}


.wp-block-buttons > .is-style-arrow-link {
	// margin-top of arrow links smaller, otherwise margin would be too large
	margin-top: $margin-button / 2;
	margin-bottom: rem(4px);
}

.wp-block-buttons > .is-style-arrow-link:first-child {
	margin-top: $margin-button;
}
