/**
  Base styles i.e. body, html, font family
*/

// needs to be seperate for editor styles (as no body ele)
@mixin body {
	font-family: Montserrat, sans-serif;
	// same as utilities.scss .has-medium-font-size
	font-size: $font-size-small-body;
	font-weight: $weight-medium;
	line-height: $line-height-small-body;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-body;
		font-weight: $weight-regular;
		line-height: $line-height-body;
	}
}

/* Set core body defaults */
body {

	@include body;
}

// fade in lazy loaded assets
[data-src] {
	opacity: 0;
	transition: opacity 0.2s linear;
}

.lazyloaded {
	opacity: 1;
}
