.c-social-links {
  display: flex;
  justify-content: flex-end;
  margin: rem(-10px);
}

.c-social-links__link {
  padding: rem(16px);
  fill: var(--color-azure-blue);
  transition: fill 0.2s ease-in-out;
}

.c-social-links__link:hover,
.c-social-links__link:focus {
  fill: var(--color-yellow);
}

//social Links for below posts

.c-social-links-in-post {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-social-links-in-post .c-social-links__link {
  fill: var(--color-wizard-blue-tint-2);
}

.c-social-links-in-post .c-social-links__link:hover,
.c-social-links-in-post .c-social-links__link:focus {
  fill: var(--color-coral);
}
