$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 700;

$letter-spacing-subheading: 0.115em;
$line-height-subheading: 1.86;

/*
Font styles (should match Figma naming convention)
*/

$letter-spacing-bold: -0.02em;

$font-size-h1: rem(25px);
$font-size-h1-sm: rem(42px);
$font-size-h1-lg: 3.479vw;
$font-size-h1-xl: rem(48px);
$line-height-h1: 1.27;

$font-size-h2: rem(24px);
$font-size-h2-sm: rem(42px);
$font-size-h2-lg: 3.044vw;
$font-size-h2-xl: rem(42px);
$line-height-h2: 1.29;

$font-size-h3: rem(19px);
$font-size-h3-sm: rem(34px);
$font-size-h3-lg: 2.47vw;
$font-size-h3-xl: rem(34px);
$line-height-h3: 1.35;

$font-size-h4: rem(24px);
$line-height-h4: 1.5;

$font-size-h5: rem(14px);
$line-height-h5: 1.86;
$letter-spacing-h5: 0.115em;

$font-size-h6: rem(17px);
$line-height-h6: 1.71;
$letter-spacing-h6: 0.115em;

$font-size-large-body: rem(25px);
$font-size-large-body-lg: 1.82vw;
$font-size-large-body-xl: rem(25px);
$line-height-large-body: 1.56;
$letter-spacing-large-body: -0.005em;

$font-size-body: rem(19px);
$line-height-body: 1.68;

$font-size-small-body: rem(16px);
$line-height-small-body: 1.75;
$letter-spacing-small-body: -0.005em;

$font-size-extra-small-body: rem(14px);
$line-height-extra-small-body: 1.43;
$letter-spacing-extra-small-body: -0.005em;

$border-radius-pill: 9999em;

$montserrat: "Montserrat";

/*
  Container size breakpoints
*/

$container-padding: 10%;
$container-padding-lg: 8.696%;
$container-padding-xl: 120px;

$container-padding-x: (
	default: $container-padding,
	lg: $container-padding-lg,
	xl: $container-padding-xl,
);

$container-inner-width: 100% - $container-padding * 2;
$container-inner-width-lg: 100% - $container-padding-lg * 2;
$container-inner-width-xl: 1136px;

$container-outer-width: 100%;
$container-outer-width-lg: 100%;
$container-outer-width-xl: $container-inner-width-xl + $container-padding-xl * 2;

$padded-container-inner-width: 941px;
$padded-container-outer-width-xl: $padded-container-inner-width +
	$container-padding-xl * 2;

$grid-breakpoints: (
	xs: 375px,
	sm: 640px,
	md: 768px,
	lg: 1024px,
	xl: $container-outer-width-xl,
	xxl: 1768px,
);

$container-max-widths: (
	default: 100%,
	padded: $padded-container-outer-width-xl,
	xl: $container-outer-width-xl,
);

// standard margin between most smaller elements i.e. h6
$margin-y-xs: rem(10px);
$margin-y-sm: rem(20px);
// standard margin between most text content i.e. h1, p
$margin-y-md: rem(40px);

$padding-table-cell: rem(22px);

// margin between large block elements i.e. header, etc
$row-margin-y: rem(60px);
$row-margin-y-sm: rem(90px);
$row-margin-y-lg: 7.247vw;
$row-margin-y-xl: rem(120px);

$row-margins-y: (
	default: $row-margin-y,
	md: $row-margin-y-sm,
	lg: $row-margin-y-lg,
	xl: $row-margin-y-xl,
);

// should match Figma grid
$grid-column-gutter-lg: 3.5%;
$grid-column-gutter-xl: rem(42px);
// this is a special placeholder margin for 2 column layouts
$grid-2-column-gutter-lg: 5.29%;

$group-padding-sm: rem(30px);
$group-padding-md: rem(60px);
$group-padding-lg: rem(140px);

$z-index-navbar: 100;
$z-index-subnav: 99;
$margin-navbar: 15px;
$padding-navbar: 15px;
$navbar-inner-height: 70px;
$navbar-height: $navbar-inner-height + $margin-navbar * 2;
$navbar-inner-height-lg: 74px;
$navbar-height-lg: $navbar-inner-height-lg + $margin-navbar * 2;

// wp admin bar spacing i.e. when logged in the bar that follows you
$customizer-offset: 46px;
$customizer-breakpoint: 600px;
$customizer-offset-lg: 32px;
$customizer-breakpoint-lg: 783px;

$line-height-button: 1.4;
$border-width-button: 2px;
$font-size-button: rem(15px);
$padding-button: 0.434em 1.07em;
$margin-button: rem(20px);

// animation

$ease-quad-out: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-quad-in-out: cubic-bezier(0.46, 0.03, 0.52, 0.96);
