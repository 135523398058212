// basic CSS spinner for use in forms, etc
.c-loading-spinner {
	position: relative;
	width: 60px;
	height: 60px;
	padding: 0 !important;
	margin: $margin-y-md auto 0;
	animation: c-loading-spinner 4s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55);
	perspective: 100px;
	transform-style: preserve-3d;
}

.c-loading-spinner::before,
.c-loading-spinner::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	background-size: 100% 100%;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	content: "";
}

.c-loading-spinner::before {
	background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath d='M38.262 40H1.738C.765 40 0 39.2 0 38.262V1.738C0 .765.8 0 1.738 0h36.524C39.235 0 40 .8 40 1.738v36.524c0 .939-.8 1.738-1.738 1.738z' fill='%23fff'/%3E%3Cpath d='M35.9 14.84H25.015v4.236h10.883V14.84z' fill='%231F00B4'/%3E%3Cpath d='M35.9 20.298H25.015v4.236h10.883v-4.236z' fill='%23E2E6ED'/%3E%3Cpath d='M13.207 10.05c-2.87 0-5.432 1.32-7.165 3.39l3.949 3.448a4.178 4.178 0 013.216-1.517c2.331 0 4.219 1.93 4.219 4.315 0 2.384-1.888 4.315-4.219 4.315a4.18 4.18 0 01-3.294-1.616l-4.045 3.33c1.734 2.188 4.373 3.606 7.34 3.606 5.2 0 9.419-4.315 9.419-9.635.02-5.32-4.2-9.635-9.42-9.635z' fill='%231F00B4'/%3E%3Cpath d='M8.989 19.687c0-1.064.385-2.05 1.002-2.798L6.04 13.44a9.69 9.69 0 00-2.253 6.247c0 2.285.79 4.394 2.08 6.049l4.046-3.33a4.399 4.399 0 01-.925-2.72z' fill='%23E2E6ED'/%3E%3C/svg%3E");
}

.c-loading-spinner::after {
	background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41 40'%3E%3Cpath d='M38.37 40H1.845c-.973 0-1.738-.8-1.738-1.738V1.738C.107.765.907 0 1.845 0H38.37c.973 0 1.737.8 1.737 1.738v36.524c0 .939-.8 1.738-1.737 1.738z' fill='%231B0B53'/%3E%3Cpath d='M15.926 6.897H8.761v7.507h7.165V6.897z' fill='%230E4DFB'/%3E%3Cpath d='M11.86 12.065l-1.233-1.291.466-.524.767.838 1.767-1.85.466.488-2.233 2.34z' fill='%23fff'/%3E%3Cpath d='M31.057 11.367H18.159c-.1 0-.167-.314-.167-.698 0-.384.067-.698.167-.698H31.09c.1 0 .167.314.167.698-.034.384-.1.698-.2.698z' fill='%23E2E6ED'/%3E%3Cpath d='M15.926 16.429H8.761v7.507h7.165v-7.507z' fill='%231F00B4'/%3E%3Cpath d='M11.753 19.088l-.472.494 1.697 1.778.472-.494-1.697-1.778z' fill='%23fff'/%3E%3Cpath d='M12.978 19.088l-1.697 1.777.472.494 1.697-1.778-.472-.493z' fill='%23fff'/%3E%3Cpath d='M31.057 20.899H18.159c-.1 0-.167-.315-.167-.699 0-.384.067-.698.167-.698H31.09c.1 0 .167.314.167.698-.034.384-.1.699-.2.699z' fill='%23E2E6ED'/%3E%3Cpath d='M15.926 25.962H8.761v7.507h7.165v-7.507z' fill='%230E4DFB'/%3E%3Cpath d='M11.86 31.164l-1.233-1.327.466-.488.767.802 1.767-1.815.466.489-2.233 2.34z' fill='%23fff'/%3E%3Cpath d='M31.057 30.43H18.159c-.1 0-.167-.313-.167-.697 0-.385.067-.699.167-.699H31.09c.1 0 .167.314.167.699-.034.384-.1.698-.2.698z' fill='%23E2E6ED'/%3E%3C/svg%3E");
	transform: rotateY(180deg);
}

@keyframes c-loading-spinner {

	0% {
		transform: perspective(200px) rotateY(0deg);
	}

	100% {
		transform: perspective(200px) rotateY(360deg);
	}
}
