$padding-link: rem(15px);

.c-main-nav {
	position: relative;
	z-index: $z-index-navbar;
	top: 0;
	overflow: hidden;
	margin-bottom: rem(40px);
}

.c-main-nav__container {
	position: relative;
	display: flex;

	align-items: center;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-top: 0;
	list-style: none;
}

.c-main-nav__logo {
	margin-top: auto;
	margin-bottom: auto;
	grid-area: logo;
	transition: transform 0.2s var(--ease-quad-out);
}

.c-main-nav__logo:hover,
.c-main-nav__logo:focus {
	transform: scale(1.05);
}

.c-main-nav__logo img {
	display: inline-block;
	width: auto;
	width: 100%;
	max-width: rem(115px);
	margin-top: 0;
	vertical-align: middle;

	@include media-breakpoint-min(sm) {
		max-width: rem(170px);
	}
}

.c-main-nav__item {
	margin: 0;
}

.c-main-nav__item:last-child {
	margin-left: rem(15px);
}

.c-main-nav__item a {
	color: white;
	font-size: rem(15px);
	font-weight: $weight-bold;
	text-decoration: none;
}

.c-main-nav__item a:hover,
.c-main-nav__item a:focus {
	text-decoration: underline;
}
