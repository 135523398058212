$inner-container-size: 941px;
$outer-container-size: $inner-container-size + $container-padding-xl * 2;

.c-steps {
	margin-top: rem(50px);
	list-style: none;

	@include media-breakpoint-min(sm) {
		display: flex;
	}
}

.c-step {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 0;
	margin-left: 0;
	transition: opacity 0.2s linear;

	@include media-breakpoint-min(sm) {
		width: percentage(346px / $inner-container-size);
		flex-direction: row;
		align-items: flex-start;
	}
}

.c-step__container {
	max-width: 254px;
}

.c-step__image {
	margin: 0;
}

.c-step:last-child {

	@include media-breakpoint-min(sm) {
		width: percentage(254px / $inner-container-size);
	}
}

.c-step__arrow {
	margin-top: rem(30px);
	margin-bottom: rem(30px);
	transform: rotate(90deg);

	@include media-breakpoint-min(sm) {
		width: percentage(40px / 346px);
		margin-top: 30%;
		margin-right: percentage(25px / 346px);
		margin-bottom: auto;
		margin-left: percentage(25px / 346px);
		transform: none;
	}
}
