.l-container {

	@include container();
}

.l-content-wrapper > * {

	@include container();
	@include padded-container();
}


// .l-content-wrapper > :first-child {
// 	// catch all spacing from navbar
// 	margin-top: rem(60px);

// 	@include media-breakpoint-min(lg) {
// 		margin-top: rem(100px);
// 	}
// }

// Add standard vertical spacing between larger grid elements i.e. headers
.l-row,
.l-row + *,
.l-nested-content-wrapper > :first-child {

	@include row-margin-top;
}
