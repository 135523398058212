.c-main-footer {
	position: relative;
	display: flex;

	// grow footer to left over space when page doesn't have a 100vh worth of content
	flex-direction: column;
	background-color: unset !important;
	font-size: rem(12px);
	font-weight: $weight-medium;
	letter-spacing: $letter-spacing-small-body;
	line-height: $line-height-small-body;

	li {
		margin-left: 0;
	}

	a {
		color: var(--color-white);
	}
}

.c-main-footer__wrapper {
	// always sticks to bottom of page if not enough content
	margin-top: auto;
}

.c-main-footer__container {
	justify-content: space-between;
	padding-bottom: rem(100px);

	@include media-breakpoint-min(lg) {
		display: flex;
		padding-bottom: rem(60px);
	}
}

.c-main-footer__menu-container {
	flex: 1;
}

.c-main-footer__menu {

	@include media-breakpoint-min(lg) {
		display: flex;
	}
}

// reset list styles
.c-main-footer__menu,
.c-main-footer .sub-menu {
	margin: 0;
	list-style: none;
}

// TODO: Nav walker to make these less location dependent
.c-main-footer__menu a[href] {
	text-decoration: none;
}

.c-main-footer__menu a[href]:hover {
	text-decoration: underline;
}

.c-main-footer__menu .sub-menu {
	margin-top: rem(6px);

	@include media-breakpoint-min(lg) {
		margin-top: 0;
		margin-left: 2.18vw;
	}

	@include media-breakpoint-min(xl) {
		margin-left: rem(30px);
	}
}

.c-main-footer__menu,
.c-main-footer__menu .sub-menu {
	padding-left: 0;
	list-style: none;
}

.c-main-footer__menu > .menu-item {

	@include media-breakpoint-min(lg) {
		display: flex;
		margin-right: 6%;
	}
}

.c-main-footer__menu > .menu-item:not(:first-child) {
	margin-top: $margin-y-md;

	@include media-breakpoint-min(lg) {
		margin-top: 0;
	}
}

.c-main-footer__menu .sub-menu .menu-item:not(:first-child) {
	margin-top: rem(5px);
}

.c-main-footer__menu > .menu-item > a {
	flex: none;
	font-weight: $weight-bold;
	text-transform: uppercase;
}

.c-main-footer__aside {
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: $margin-y-md;
	border-top: 1px solid rgba(var(--rgb-grey-4), 0.3);
	margin-top: $margin-y-md;

	@include media-breakpoint-min(lg) {
		display: block;
		padding-top: 0;
		border-top: none;
		margin-top: 0;
	}
}

.c-main-footer__copyright {
	margin-top: 0;
	margin-bottom: 0;
	text-align: right;

	@include media-breakpoint-min(lg) {
		margin-top: rem(95px);
	}
}

.yarpp-related + .c-main-footer {
	margin-top: 0;
}
