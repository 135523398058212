/**
* Flexbox utilities
*
* Named as per WordPress values
*/

.has-text-center {
	text-align: center;
}

.is-vertically-aligned-top {
	align-items: flex-start;
}

.is-vertically-aligned-center {
	align-items: center;
}

.is-vertically-aligned-bottom {
	align-items: flex-end;
}

.is-content-justification-left {
	justify-content: flex-start;
}

.is-content-justification-left.is-vertical {
	align-items: flex-start;
}

.is-content-justification-center {
	justify-content: center;
}

.is-content-justification-center.is-vertical {
	align-items: center;
}

.is-content-justification-right {
	justify-content: flex-end;
}

.is-content-justification-right.is-vertical {
	align-items: flex-end;
}

.is-content-justification-space-between {
	justify-content: space-between;
}

.is-content-justification-space-around {
	justify-content: space-around;
}

.has-margin-bottom-none {
	margin-bottom: 0;
}

.has-margin-bottom-small + * {
	margin-top: $margin-y-sm;
}

.has-margin-bottom-extra-small + * {
	margin-top: $margin-y-xs;
}
