.is-hidden {
	display: none !important;
	visibility: hidden !important;
}

.has-extra-small-font-size {
	margin-top: $margin-y-xs;
	font-size: rem(12px);
	font-weight: $weight-medium;
	line-height: $line-height-small-body;
}

.has-small-font-size {
	margin-top: $margin-y-xs;
	font-size: rem(14px);
	font-weight: $weight-medium;
	line-height: $line-height-small-body;
}

// Small Body Copy in Figma
.has-regular-font-size {
	margin-top: $margin-y-sm;
	font-size: $font-size-small-body;
	font-weight: $weight-medium;
	line-height: $line-height-small-body;

	+ .wp-block-buttons {
		// reduces margin of buttons
		margin-top: 0;

		// see project pages SCOM figma
		.is-style-arrow-link {
			margin-bottom: 0;

			.wp-block-button__link {
				font-size: rem(14px);
			}
		}

		.is-style-arrow-link:not(:first-child) {
			margin-top: rem(5px);
		}
	}
}

// Body Copy in Figma
.has-medium-font-size {
	// same as base.scss body
	font-size: $font-size-small-body;
	font-weight: $weight-medium;
	line-height: $line-height-small-body;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-body;
		font-weight: $weight-regular;
		line-height: $line-height-body;
	}
}

// Large Body Copy in Figma
.has-large-font-size {
	font-size: $font-size-small-body;
	font-weight: $weight-medium;
	line-height: $line-height-small-body;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-large-body;
		font-weight: $weight-regular;
		line-height: $line-height-large-body;
	}

	@include media-breakpoint-min(lg) {
		font-size: $font-size-large-body-lg;
	}

	@include media-breakpoint-min(xl) {
		font-size: $font-size-large-body-xl;
	}
}

.has-wizard-blue-color {
	color: var(--color-wizard-blue);
}

.has-wizard-blue-tint-two-labels label {
	color: var(--color-wizard-blue-tint-2);
}

.has-wizard-blue-tint-two-color {
	color: var(--color-wizard-blue-tint-2);
}

.has-coral-color {
	// !important to avoid backgrounds overriding colour
	color: var(--color-coral) !important;
}

.has-grey-three-color {
	color: var(--color-grey-3);
}

.has-medium-weight {
	font-weight: $weight-medium;
}

.has-wizard-blue-background-color,
.has-wizard-blue-tint-two-background-color,
.has-wizard-blue-to-tints-gradient-background {
	color: var(--color-white);

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	blockquote,
	cite,
	th,
	tr,
	blockquote.is-style-su-quote p,
	blockquote.is-style-su-quote cite,
	.b-icon,
	.hs-form-field > label,
	.is-style-subheading,
	.wp-block-button__link,
	.b-feature-picker-button__text {
		color: var(--color-white);
	}

	.b-hubspot-form .hs-input,
	.b-hubspot-form .hs-form-checkbox-display input + span::before {
		border-color: var(--color-white);
	}

	.su-icon,
	.su-logo__left,
	.su-logo__right {
		fill: var(--color-white);
	}

	blockquote.is-style-su-quote {

		p:first-of-type::before {
			background-image: url("data:image/svg+xml,%3Csvg id='quotation_marks_icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41 41'%3E%3Cg id='Quote' transform='translate(10 11)'%3E%3Cpath id='Path_19'  d='M7.547,10.744v8.186H0V12.407C0,8.954.384,6.4,1.279,4.733A10.585,10.585,0,0,1,6.523,0L8.314,2.814A6.719,6.719,0,0,0,5.116,5.5a9.664,9.664,0,0,0-1.151,5.244Zm12.279,0v8.186H12.279V12.407c0-3.581.384-6.012,1.279-7.675A10.585,10.585,0,0,1,18.8,0l1.663,2.814a6.719,6.719,0,0,0-3.2,2.686,12.758,12.758,0,0,0-1.151,5.244Z' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3Cg id='Group_6374'  transform='translate(-325 -511)'%3E%3Crect id='Rectangle_722'  width='41' height='41' transform='translate(325 511)' fill='rgba(0,0,0,0)'/%3E%3Crect id='Rectangle_723'  width='41' height='24' transform='translate(325 520)' fill='none'/%3E%3C/g%3E%3C/svg%3E");
		}

		p:last-of-type::after {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 41 41'%3E%3Cg id='quotation_marks_icon' transform='translate(41 41) rotate(180)'%3E%3Cg id='Quote' transform='translate(10 11)'%3E%3Cpath id='Path_19'  d='M7.547,10.744v8.186H0V12.407C0,8.954.384,6.4,1.279,4.733A10.585,10.585,0,0,1,6.523,0L8.314,2.814A6.719,6.719,0,0,0,5.116,5.5a9.664,9.664,0,0,0-1.151,5.244Zm12.279,0v8.186H12.279V12.407c0-3.581.384-6.012,1.279-7.675A10.585,10.585,0,0,1,18.8,0l1.663,2.814a6.719,6.719,0,0,0-3.2,2.686,12.758,12.758,0,0,0-1.151,5.244Z' transform='translate(0 0)' fill='%23fff'/%3E%3C/g%3E%3Cg id='Group_6374'  transform='translate(-325 -511)'%3E%3Crect id='Rectangle_722'  width='41' height='41' transform='translate(325 511)' fill='rgba(0,0,0,0)'/%3E%3Crect id='Rectangle_723'  width='41' height='24' transform='translate(325 520)' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		}
	}

	tr:nth-child(even) {
		background-color: var(--color-violet-blue);
	}

	.b-feature-picker-icon-button__icon .c-icon,
	.b-feature-picker-icon-button.swiper-pagination-bullet .c-icon {
		fill: var(--color-white);
	}

	.b-feature-picker-icon-button.swiper-pagination-bullet.b-feature-picker-icon-button__active
	.c-icon {
		fill: var(--color-coral);
	}

	.b-feature-picker-icon-button.swiper-pagination-bullet.b-feature-picker-icon-button__active
	.b-feature-picker-button__text {
		color: var(--color-coral);
	}

	.b-feature-picker-icon-button.swiper-pagination-bullet.b-feature-picker-icon-button__active {
		background-color: unset;
	}
}

.has-grey-six-background-color {
	background-color: var(--color-grey-6);

	.b-feature-picker__slide.swiper-slide {
		background-color: var(--color-grey-6);
	}
}

.has-light-blue-two-background-color {
	background-color: var(--color-light-blue-2);

	.b-feature-picker__slide.swiper-slide {
		background-color: var(--color-light-blue-2);
	}
}

.has-wizard-blue-background-color {
	background-color: var(--color-wizard-blue);

	.b-feature-picker__slide.swiper-slide {
		background-color: var(--color-wizard-blue);
	}
}

.has-wizard-blue-tint-two-background-color {
	background-color: var(--color-wizard-blue-tint-2);

	.b-feature-picker__slide.swiper-slide {
		background-color: var(--color-wizard-blue-tint-2);
	}
}

.has-white-background-color {
	background-color: var(--color-white);

	.b-feature-picker__slide.swiper-slide {
		background-color: var(--color-white);
	}
}

.has-wizard-blue-to-tints-gradient-background {
	background:
 linear-gradient(to bottom right, var(--color-wizard-blue), var(--color-wizard-blue-tint-2) 74.73%, var(--color-wizard-blue-tint-3));
}

.alignfull,
.su-content-wrapper > .alignfull,
.alignfull > .wp-block-group__inner-container > .alignfull,
.alignfull > .wp-block-group__inner-container > [data-align="full"] {
	max-width: none;
	padding-right: 0;
	padding-left: 0;
}

/**
*	Reset the list item indent added by elements.scss
*/
// utility margin-left 0 (similar to tailwind)
.u-ml-0 {
	margin-left: 0;
}
