h1,
h2,
h3,
h4,
h5 {
	font-weight: $weight-bold;
}

h1,
h2,
h3,
h4,
h6 {
	color: var(--color-wizard-blue-tint-2);
	letter-spacing: $letter-spacing-bold;
}

h5,
h6 {

	a {

		display: inline-block;
		color: inherit;
		text-decoration: none;

		&::after {

			@include link-arrow;
		}

		&:hover,
		&:focus,
		&:active {

			&::after {

				@include link-arrow-transition;
			}
		}
	}
}

// TODO: other arrow links for headings
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {

// 	a {

// 		&::after {


// 		}

// 	}

// }


h1 {
	font-size: $font-size-h1;
	line-height: $line-height-h1;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-h1-sm;
	}

	@include media-breakpoint-min(lg) {
		font-size: $font-size-h1-lg;
	}

	@include media-breakpoint-min(xl) {
		font-size: $font-size-h1-xl;
	}
}

h2 {
	font-size: $font-size-h2;
	line-height: $line-height-h2;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-h2-sm;
	}

	@include media-breakpoint-min(lg) {
		font-size: $font-size-h2-lg;
	}

	@include media-breakpoint-min(xl) {
		font-size: $font-size-h2-xl;
	}
}

h3 {
	font-size: $font-size-h3;
	line-height: $line-height-h3;

	@include media-breakpoint-min(sm) {
		font-size: $font-size-h3-sm;
	}

	@include media-breakpoint-min(lg) {
		font-size: $font-size-h3-lg;
	}

	@include media-breakpoint-min(xl) {
		font-size: $font-size-h3-xl;
	}
}

h4 {
	font-size: $font-size-h4;
	line-height: $line-height-h4;
}

h4 + .has-regular-font-size {
	margin-top: $margin-y-sm;
}

h5 {
	color: var(--color-wizard-blue);
	font-size: $font-size-h5;
	letter-spacing: $letter-spacing-h5;
	line-height: $line-height-h5;
	text-transform: uppercase;
}

h6 {
	font-size: $font-size-h6;
	line-height: $line-height-h6;
}

h6 + .has-regular-font-size {
	margin-top: $margin-y-xs;
}


.b-tier-card + h3 {
	margin-top: $row-margin-y;
}
