/**
* Form components
*/

.c-input,
.hs-input {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: rem(10px) rem(21px);
	border: 1px solid var(--color-grey-4);
	margin-top: rem(14px);
	background-color: var(--color-white);
	border-radius: 5px;
	color: var(--color-grey-1);
	cursor: pointer;
	font-size: $font-size-small-body;
	font-weight: $weight-medium;
	letter-spacing: $letter-spacing-small-body;
	line-height: $line-height-small-body;
}

.c-input-desc,
.hs-field-desc {
	order: 1;
	margin-top: rem(3px);
	font-size: rem(14px);
	font-weight: $weight-medium;
}

.c-input-error,
.hs-error-message {

	@include error-message();
	margin-top: rem(14px);
}

.c-input-success,
.submitted-message {
	margin-top: $margin-y-md;

	@include success-message();
}

.hs-form-field > label,
.c-input-label {
	display: block;
	margin-top: $margin-y-sm;
	color: var(--color-wizard-blue);
	font-size: $font-size-h5;
	font-weight: $weight-bold;
	letter-spacing: $letter-spacing-h5;
	text-transform: uppercase;
}

select {
	padding-right: rem(43px); // allow space for chevron
	appearance: none;

	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='9.4' height='6.1' viewBox='0 0 9.4 6.1'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='9.4' height='6.1' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Symbol_156_6'  clip-path='url(%23clip-path)'%3E%3Cpath id='Path_36'  d='M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z' transform='translate(-2 -2)' fill='%23fd5f1f'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-position: calc(100% - #{rem(23px)}) 50%;
	background-repeat: no-repeat;
	background-size: rem(9.4px) rem(6.1px);
}

option,
select,
input {
	color: var(--color-grey-1);
}

input::placeholder,
select.is-placeholder,
select:invalid,
[disabled] {
	color: var(--color-grey-2);

	opacity: 1;
}

// we hide
.c-input__radio {
	position: absolute;
	opacity: 0;
}

.c-input .c-icon {
	display: inline;
	height: rem(25px);
	margin-right: rem(10px);
}

.c-input--coral-icon .c-icon {
	fill: var(--color-coral);
}

.c-button-group {
	position: relative;
	overflow: hidden;
	border: 1px solid var(--color-grey-4);
	margin-top: rem(10px);

	background-color: var(--color-white);
	border-radius: 5px;

	@include media-breakpoint-min(lg) {
		display: flex;
	}
}

.c-button-group__inner-group {
	flex: 1 1 0;

	@include media-breakpoint-min(lg) {
		display: flex;
	}
}

.c-button-group__highlight {
	position: absolute;
	height: 100%;
	background-color: #d6eaff;
}

.c-button-group__button {
	position: relative;
	flex: 1 1 0;

	background-color: rgba(0, 0, 0, 0);
}

.c-button-group .c-button-group__button {
	border-top: none;
	border-left: none;
	margin-top: 0;
	border-radius: 5px 0 0 5px;

	@include media-breakpoint-min(lg) {
		border-bottom: none;
	}

	@include media-breakpoint-max(lg) {
		border-right: none;
	}
}

.c-button-group .c-button-group__button:last-child {
	border-right: none;
	border-bottom: none;
	border-radius: 5px;
}

.c-input--active {
	background-color: #d6eaff;
}

.c-search {
	max-width: rem(410px);
	flex: 1;
	margin-left: auto;
}

.c-search__field {
	padding-top: rem(4px);
	padding-right: rem(22px);
	padding-bottom: rem(4px);
	margin-top: 0;
	background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMy45NCcgaGVpZ2h0PScxMy45NDInPjxwYXRoIGQ9J00xMy43NSAxMi4wNTRsLTIuNzE0LTIuNzE1YS42NTMuNjUzIDAgMDAtLjQ2My0uMTkxaC0uNDQ0YTUuNjYxIDUuNjYxIDAgMTAtLjk4Ljk4di40NDRhLjY1My42NTMgMCAwMC4xOTEuNDYzbDIuNzE1IDIuNzE1YS42NTEuNjUxIDAgMDAuOTIzIDBsLjc3MS0uNzcxYS42NTcuNjU3IDAgMDAuMDAxLS45MjV6TTUuNjYzIDkuMTQ5YTMuNDg1IDMuNDg1IDAgMTEzLjQ4Ni0zLjQ4NiAzLjQ4MyAzLjQ4MyAwIDAxLTMuNDg2IDMuNDg2eicgZmlsbD0nIzlhOWE5YScvPjwvc3ZnPg==);
	background-position: 95% 50%;
	background-repeat: no-repeat;
}

.c-input-text-area {
	display: block;
	width: 100%;
	height: 167px;
	padding: rem(7px) rem(16px);
	border: none;
	margin-top: $margin-y-md;
	border-radius: 5px;
	resize: vertical;
}
