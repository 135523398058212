@mixin font-face($name, $path, $weight: normal, $style: normal) {

	@font-face {
		font-display: swap;
		font-family: unquote($name);
		font-style: $style;
		font-weight: $weight;
		src:
			url("#{$path}.woff2") format("woff2"),
			url("#{$path}.woff") format("woff");
	}
}

@include font-face($montserrat, "../assets/fonts/montserrat-regular-latin");
@include font-face(
	$montserrat,
	"../assets/fonts/montserrat-regular-latin-italic",
	$style: italic
);
@include font-face(
	$montserrat,
	"../assets/fonts/montserrat-medium-latin",
	$weight: 500
);
@include font-face(
	$montserrat,
	"../assets/fonts/montserrat-medium-latin-italic",
	$weight: 500,
	$style: italic
);
@include font-face(
	$montserrat,
	"../assets/fonts/montserrat-bold-latin",
	$weight: 700
);
@include font-face(
	$montserrat,
	"../assets/fonts/montserrat-bold-latin-italic",
	$weight: 700,
	$style: italic
);
