@mixin container {
	width: 100%;
	max-width: 100%;
	padding-right: map-get($map: $container-padding-x, $key: "default");
	padding-left: map-get($map: $container-padding-x, $key: "default");
	margin-right: auto;
	margin-left: auto;

	@include media-breakpoint-min(lg) {
		padding-right: map-get($map: $container-padding-x, $key: "lg");
		padding-left: map-get($map: $container-padding-x, $key: "lg");
	}

	@include media-breakpoint-min(xl) {
		max-width: map-get($map: $container-max-widths, $key: "xl");
		padding-right: map-get($map: $container-padding-x, $key: "xl");
		padding-left: map-get($map: $container-padding-x, $key: "xl");
	}
}

@mixin line-clamp($lines: 3) {
	display: -webkit-box;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $lines;
}

@mixin padded-container {

	@include media-breakpoint-min($padded-container-outer-width-xl) {
		max-width: $padded-container-outer-width-xl;
		padding-right: map-get($map: $container-padding-x, $key: "xl");
		padding-left: map-get($map: $container-padding-x, $key: "xl");
	}
}

/**
* mixin to show inset shadows for filter dropdown & playlist header
*/
@mixin vertical-inset-shadows() {

	&::before,
	&::after {
		position: absolute;
		left: 0;
		left: 50%;

		display: block;
		width: 100vw;
		height: 1.7rem;

		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));

		content: "";
		opacity: 0.2;
	}

	&::before {
		z-index: 10;
		top: 0;

		transform: translateX(-50%);
	}

	&::after {
		bottom: 0;

		transform: rotate(180deg) translateX(-50%) translateY(-100%);
		transform-origin: 0 0;
	}
}

@mixin status-message($color) {
	padding: rem(17px) rem(28px);
	border: 1px solid;

	border-radius: 4px;
	font-size: rem(16px);
	font-weight: $weight-medium;

	list-style: none;

	> :first-child {
		margin-top: 0;
	}

	&,
	h2,
	h3,
	h4,
	h5,
	h6,
	label,
	strong {
		color: $color !important;
	}
}

@mixin error-message {

	@include status-message(var(--color-error-1));
	border-color: var(--color-error-2);
	background: var(--color-error-3);
	font-weight: $weight-bold;
}

@mixin success-message {

	@include status-message(var(--color-success-1));
	border-color: var(--color-success-2);
	background: var(--color-success-3);
}

/**
* Mixin to get the scrollbar styling we use in Figma
*/
@mixin scrollbar($color: var(--color-grey-3)) {
	scrollbar-color: $color transparent; /* thumb and track color */
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 21px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border: 6px solid rgba(0, 0, 0, 0);
		background: $color;
		background-clip: padding-box;
		border-radius: 20px;
	}
}

/**
*	Additional styles for editorial content
*
* Reduced font sizes, etc.
*/
@mixin editorial-content {
	hyphens: auto;
	word-wrap: break-word;

	h2 {
		font-size: rem(21px);

		@include media-breakpoint-min(lg) {
			font-size: rem(28px);
		}
	}

	h3 {
		font-size: rem(18px);

		@include media-breakpoint-min(lg) {
			font-size: rem(21px);
		}
	}

	h3 + h4,
	h2 + h4,
	h2 + h3 {
		margin-top: $margin-y-sm;

		@include media-breakpoint-min(lg) {
			margin-top: $margin-y-xs;
		}
	}

	figure,
	img,
	picture {

		+ * {
			margin-top: rem(40px);
		}
	}

	.b-hubspot-form {

		+ * {
			margin-top: $row-margin-y;

			@include media-breakpoint-min(lg) {
				margin-top: $row-margin-y-sm;
			}
		}
	}

	p,
	pre,
	ul,
	ol,
	em,
	picture,
	figure,
	img,
	.wp-block-buttons,
	.b-hubspot-form,
	q {

		+ h2 {
			margin-top: rem(80px);
		}

		+ h3,
		+ q {
			margin-top: rem(56px);
		}

		+ h4 {
			margin-top: rem(56px);
		}
	}

	hr {
		margin: rem(56px) 0;
	}
}

@mixin row-margin-top {
	margin-top: $row-margin-y;

	@include media-breakpoint-min(sm) {
		margin-top: $row-margin-y-sm;
	}

	@include media-breakpoint-min(lg) {
		margin-top: $row-margin-y-lg;
	}

	@include media-breakpoint-min(xl) {
		margin-top: $row-margin-y-xl;
	}
}

// use to offset margin bottom
@mixin -row-margin-bottom {
	margin-bottom: -$row-margin-y;

	@include media-breakpoint-min(sm) {
		margin-bottom: -$row-margin-y-sm;
	}

	@include media-breakpoint-min(lg) {
		margin-bottom: -$row-margin-y-lg;
	}

	@include media-breakpoint-min(xl) {
		margin-bottom: -$row-margin-y-xl;
	}
}

@mixin link-arrow {

	@supports (mask: url("")) {
		display: inline-block;
		width: 11px;
		height: 12px;
		margin-left: rem(8px);
		background-color: currentColor;
		content: "";
		// only approach i can think of to transition 2 elements & only need 1 css class, with auto coloration of the arrows
		// only downside is fairly new feature so
		mask-image:
			url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='10' preserveAspectRatio='xMinYMid slice' y1='6' y2='6' stroke='%23160080' stroke-width='2'/%3E%3C/svg%3E%0A"),
			url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.41176 12L7.41177 6L1.41177 0L2.23027e-06 1.41177L4.58824 6L0 10.5882L1.41176 12Z' fill='%23160080'/%3E%3C/svg%3E%0A");
		mask-position: 7px 50%, 0 50%;
		mask-repeat: no-repeat;
		mask-size: 0 12px, auto auto;
		transition:
			mask 0.15s var(--ease-quad-out),
			transform 0.3s var(--ease-quad-out),
			opacity 0.15s var(--ease-quad-out);
		vertical-align: middle;
	}
}

// attach to whatever should trigger the hover
@mixin link-arrow-transition {
	mask-position: 0 50%, 4px 50%;
	mask-size: 12px 12px, auto auto;
}
