.wp-block-button__link {
	position: relative;
	display: inline-block;
	padding: $padding-button;
	border: $border-width-button solid var(--color-coral);
	background-color: var(--color-coral);
	border-radius: $border-radius-pill !important; // ignore button border radius
	color: var(--color-white);
	cursor: pointer;
	font-size: $font-size-button;
	font-weight: $weight-bold;
	line-height: $line-height-button;
	overflow-wrap: break-word;
	text-align: center;
	text-decoration: none;

	transition:
		background 0.3s var(--ease-quad-out),
		border 0.3s var(--ease-quad-out),
		color 0.3s var(--ease-quad-out);


	&::after {

		@include link-arrow;
	}

	&:hover,
	&:focus {
		border-color: var(--color-coral-hover);
		background-color: var(--color-coral-hover);
	}

	&:active {
		border-color: var(--color-coral-pressed);
		background-color: var(--color-coral-pressed);
	}

	&:hover,
	&:focus,
	&:active {

		&::after {

			@include link-arrow-transition;
		}
	}
}

/**
* allow for icons in the cta in any position
* fairly basic and doesn't support fill as is
*/
.wp-block-button__link img {
	display: inline-block;
	margin-top: 0;
	vertical-align: middle;
}


.wp-block-button__link--disabled {
	border-color: var(--color-grey-2) !important;
	background-color: rgba(0, 0, 0, 0) !important;
	color: var(--color-grey-2) !important;

	&:hover,
	&:focus,
	&:active {

		&::after {
			mask-position: 7px 50%, 0 50%;
			mask-size: 0 12px, auto auto;
		}
	}
}

.wp-block-button__link--tooltip {

	&::after {
		width: 16px;
		height: 16px;
		background-color: var(--color-coral);
		mask: url("data:image/svg+xml,%3Csvg viewBox='0 0 31 31' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28 15.5C28 22.404 22.404 28 15.5 28S3 22.404 3 15.5 8.596 3 15.5 3 28 8.596 28 15.5zm3 0C31 24.06 24.06 31 15.5 31 6.94 31 0 24.06 0 15.5 0 6.94 6.94 0 15.5 0 24.06 0 31 6.94 31 15.5zm-13.809 3.301h-2.915v-3.52h1.427c1.588-.032 2.394-.878 2.394-2.508a3.373 3.373 0 00-.794-2.107 2.611 2.611 0 00-.908-.549 2.562 2.562 0 00-1.046-.138 2.343 2.343 0 00-.97.125c-.312.11-.598.284-.841.512a2.866 2.866 0 00-.62 1.763l-2.916.14a5.818 5.818 0 011.414-3.966 5.18 5.18 0 011.801-1.198 5.072 5.072 0 012.12-.349 5.4 5.4 0 012.16.364c.69.268 1.32.675 1.853 1.196A6.346 6.346 0 0121 12.792c0 2.152-1.03 4.64-3.821 5.322l.012.687zm.062 1.662H14.35v2.979h2.903v-2.98z'/%3E%3C/svg%3E");
		mask-position: 0 0 !important;
		mask-size: 100% 100% !important;
		vertical-align: -3px;
	}
}

.is-style-outline.wp-block-button__link,
.is-style-outline .wp-block-button__link {
	padding: $padding-button;
	border-color: var(--color-coral);
	background-color: rgba(0, 0, 0, 0);
	color: var(--color-coral);
	text-align: inherit;

	&:hover,
	&:focus {
		border-color: var(--color-coral-hover);
		background-color: var(--color-coral-hover);
		color: var(--color-white);
	}

	&:active {
		border-color: var(--color-coral-pressed);
		background-color: var(--color-coral-pressed);
	}
}


.is-style-arrow-link .wp-block-button__link {
	padding: 0;
	color: var(--color-accent-blue);
	font-size: rem(16px);
	text-align: inherit;

	&,
	&:focus,
	&:hover {
		border: none;
		background: none;
	}
}

// if next to another style cta make same font-size
.wp-block-button:not(.is-style-arrow-link) ~ .is-style-arrow-link .wp-block-button__link {
	font-size: rem(15px);
}

.is-style-arrow-link {
	color: var(--color--accent-blue);
}


.is-style-dropdown .wp-block-button__link::after {
	transform: rotate(90deg);
	vertical-align: -3px;
}

.is-style-dropdown .wp-block-button__link:hover::after,
.is-style-dropdown .wp-block-button__link:focus::after {
	transform: translateY(-2px) rotate(90deg);
}

.js-visible.is-style-dropdown .wp-block-button__link::after {
	transform: translateY(-4px) rotate(270deg);
}

.js-visible.is-style-dropdown .wp-block-button__link:hover::after,
.js-visible.is-style-dropdown .wp-block-button__link:focus::after {
	transform: translateY(-3px) rotate(270deg);
}
